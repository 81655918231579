import { admin2 } from './instance'

export const GetMemberInviteConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/referralActivity`,
  })
}

export const GetMemberInviteRelatedEvent = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/referralActivity/referralEventTemplateConfig`,
  })
}

export const UpdateMemberInviteConfig = async ({
  shopId,
  allowReferralRegister,
  displayReferralActivity,
  description,
  shareMessage,
  shareImageIds,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/referralActivity`,
    data: {
      allowReferralRegister,
      displayReferralActivity,
      description,
      shareMessage,
      shareImageIds,
    },
  })
}

// member invited
export const FindMemberInviteRecordByRefereeId = async ({ shopId, refereeId }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberReferralRecord/byRefereeId`,
    params: {
      RefereeId: refereeId,
    },
  })
}

export const GetMemberInviteRecord = async ({
  shopId,
  start,
  limit,
  ReferrerId,
  RefereeId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberReferralRecord`,
    params: {
      start,
      limit,
      ReferrerId,
      RefereeId,
    },
  })
}
export const GetMemberInviteRecordCount = async ({
  shopId,
  ReferrerId,
  RefereeId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberReferralRecord/count`,
    params: {
      ReferrerId,
      RefereeId,
    },
  })
}

// report
export const GetMemberInviteReportMetrics = async ({
  shopId,
  start,
  end,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberReferralReport/metrics`,
    params: {
      start,
      end,
    },
  })
}

export const GetMemberInviteReportRank = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberReferralReport/referralRank`,
  })
}

export const GetMemberInviteReportRecord = async ({
  shopId,
  start,
  limit,
  RefereeId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberReferralReport/referralRecord`,
    params: {
      start,
      limit,
      RefereeId,
    },
  })
}

export const GetMemberInviteReportRecordCount = async ({
  shopId,
  RefereeId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberReferralReport/referralRecord/count`,
    params: {
      RefereeId,
    },
  })
}
